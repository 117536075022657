import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Newsletter.css';

import { useCallback } from "react";

const useGoogleAdsConversion = () => {
  const reportConversion = useCallback((url: any) => {
    const callback = () => {
      if (url) {
        window.location = url;
      }
    };

    if ((window as any).gtag) {
      (window as any).gtag("event", "conversion", {
        send_to: "AW-16680078388/2BuDCPm_75kaELSY15E-", 
        value: 1.0,
        currency: "CAD",
        event_callback: callback,
      });
    } else {
      console.warn("gtag is not available");
    }
  }, []);

  return reportConversion;
};

const Newsletter = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cookingGoal: '',
    cookingGoalOther: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const reportConversion = useGoogleAdsConversion();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const submissionData = {
      name: formData.name,
      email: formData.email,
      cookingGoal: formData.cookingGoal === 'Other' ? formData.cookingGoalOther : formData.cookingGoal,
    };

    const response = await fetch('https://us-east1-yoridachi.cloudfunctions.net/createContact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submissionData),
    });

    if (response.ok) {
      setFormSubmitted(true);
      reportConversion(null);
    } else {
      alert('An error occurred. Please try again later.');
    }
  };

  const openPrivacyPolicy = () => {
    window.open('#/privacy-policy', '_blank');
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/chefyori/', '_blank');
  };

  const openFacebook = () => {
    window.open('https://www.facebook.com/chefyori', '_blank');
  };

  const openTikTok = () => {
    window.open('https://www.tiktok.com/@chefyori', '_blank');
  };

  return (
    <div className='newsletter-container'>
      <div
        className='newsletter-header'
        onClick={(e) => { e.preventDefault(); navigate('/', { replace: true }) }}
      >
        Chef Yori
      </div>
      <div className='newsletter-main-content'>
        <div className='newsletter-main-content-left'>
          <div className='newsletter-title'>Get highly personalized recipes every month</div>
          <div className='newsletter-description'>Hello, I’m Yori. Your cooking companion to help you make delicious healthy recipes.</div>
          {formSubmitted && <div className='newsletter-success-message'>Thank you for joining!</div>}
          {!formSubmitted && <form onSubmit={handleSubmit} className="newsletter-form">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className='newsletter-input'
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange}
              className='newsletter-input'
              required
            />
            <select
              name="cookingGoal"
              value={formData.cookingGoal}
              onChange={handleChange}
              className='newsletter-cooking-goal'
              required
            >
              <option value="" disabled>What's your cooking goal?</option>
              <option value="Learn to cook from scratch">Learn to cook from scratch</option>
              <option value="Save time with efficient meal prep">Save time with efficient meal prep</option>
              <option value="Make healthier, nourishing meals">Make healthier, nourishing meals</option>
              <option value="Add more variety and flavor to your dishes">Add more variety and flavor to your dishes</option>
              <option value="Other">Other</option>
            </select>
            {formData.cookingGoal === 'Other' && <input
              type="text"
              name="cookingGoalOther"
              placeholder="Tell us your goals"
              value={formData.cookingGoalOther}
              onChange={handleChange}
              className='newsletter-input'
            />}
            <button type="submit" className='newsletter-submit-button'>Subscribe</button>
          </form>}
        </div>
        <div className='newsletter-main-content-right'>
          <img className='newsletter-image' src={`${process.env.PUBLIC_URL}/group.png`} alt='Team Chef Yori' />
        </div>
      </div>
      <div className='newsletter-testimonial-container'>
        <div className='newsletter-testimonial-image'>
          <img src={`${process.env.PUBLIC_URL}/testimonial.png`} alt='Testimonial Image' />
        </div>
        <div className='newsletter-testimonial-name-mobile'>Vivian, Toronto</div>
        <div className='newsletter-testimonial-container-right'>
          <img className='newsletter-testimonial-quotation-mark' src={`${process.env.PUBLIC_URL}/quotation-mark.png`} alt='Quotation Mark' />
          <div className='newsletter-testimonial-text-container'>
            <div className='newsletter-testimonial-text'>Before Chef Yori, I ate out all the time. Now, I am able to cook easily with step-by-step instructions. It’s perfect for beginners!</div>
            <div className='newsletter-testimonial-name'>Vivian, Toronto</div>
          </div>
        </div>
      </div>
      <div className='newsletter-whats-included-container'>
        <div className='newsletter-whats-included-title'>What’s in it for you</div>
        <div className='newsletter-whats-included-item-container'>
          <img src={`${process.env.PUBLIC_URL}/item-one.png`} />
          <div className='newsletter-whats-included-text-container'>
            <div className='newsletter-whats-included-text-title'>Try something new</div>
            <div className='newsletter-whats-included-text-subtitle'>
              You'll receive personalized recipes tailored to your tastes and dietary preferences
            </div>
          </div>
        </div>
        <div className='newsletter-whats-included-item-container'>
          <img src={`${process.env.PUBLIC_URL}/item-two.png`} />
          <div className='newsletter-whats-included-text-container'>
            <div className='newsletter-whats-included-text-title'>Become a better chef</div>
            <div className='newsletter-whats-included-text-subtitle'>
              Expertly curated cooking tips to enhance your skills and confidence in the kitchen.
            </div>
          </div>
        </div>
        <div className='newsletter-whats-included-item-container'>
          <img src={`${process.env.PUBLIC_URL}/item-three.png`} />
          <div className='newsletter-whats-included-text-container'>
            <div className='newsletter-whats-included-text-title'>Get exclusive perks</div>
            <div className='newsletter-whats-included-text-subtitle'>
              Receive notifications for early access to new features.
            </div>
          </div>
        </div>
      </div>
      <div className='newsletter-footer-form'>
        <div className='newsletter-footer-form-title'>Get highly personalized recipes every month</div>
        {formSubmitted && <div className='newsletter-success-message'>Thank you for joining!</div>}
        {!formSubmitted && <form onSubmit={handleSubmit} className="newsletter-form">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className='newsletter-input'
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Enter your email address"
            value={formData.email}
            onChange={handleChange}
            className='newsletter-input'
            required
          />
          <select
            name="cookingGoal"
            value={formData.cookingGoal}
            onChange={handleChange}
            className='newsletter-cooking-goal'
            required
          >
            <option value="" disabled>What's your cooking goal?</option>
            <option value="Learn to cook from scratch">Learn to cook from scratch</option>
            <option value="Save time with efficient meal prep">Save time with efficient meal prep</option>
            <option value="Make healthier, nourishing meals">Make healthier, nourishing meals</option>
            <option value="Add more variety and flavor to your dishes">Add more variety and flavor to your dishes</option>
            <option value="Other">Other</option>
          </select>
          {formData.cookingGoal === 'Other' && <input
              type="text"
              name="cookingGoalOther"
              placeholder="Tell us your goals"
              value={formData.cookingGoalOther}
              onChange={handleChange}
              className='newsletter-input'
            />}
          <button type="submit" className='newsletter-submit-button'>Subscribe</button>
        </form>}
      </div>
      <div className='newsletter-footer'>
        <div className='newsletter-footer-social'>
          <div className='newsletter-footer-social-image-container'>
            <img src={`${process.env.PUBLIC_URL}/instagram.png`} alt='Instagram' onClick={openInstagram} />
          </div>
          <div className='newsletter-footer-social-image-container'>
            <img src={`${process.env.PUBLIC_URL}/facebook.png`} alt='Facebook' onClick={openFacebook} />
          </div>
          <div className='newsletter-footer-social-image-container'>
            <img src={`${process.env.PUBLIC_URL}/tiktok.png`} alt='TikTok' onClick={openTikTok} />
          </div>
        </div>
        <div className='newsletter-privacy-policy' onClick={openPrivacyPolicy}>Privacy Policy</div>
      </div>
    </div>
  );
}

export default Newsletter;