import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import supabase from './Supabase';
import { useAuth } from './AuthContext';

export interface Preferences {
  serving_size: string | null;
  priority_items: string[];
  duration_availability: string | null;
  avoid_items: string[];
  health_conditions: string[];
}

interface PreferencesContextType {
  preferences: Preferences;
  setPreferences: (newPreferences: Preferences) => void;
  savePreferences: () => Promise<void>;
  loadPreferences: () => Promise<void>;
  migratePreferencesToUser: () => Promise<void>;
}

const defaultPreferences: Preferences = {
  serving_size: '1 serving',
  priority_items: [],
  duration_availability: '30 minutes or less',
  avoid_items: [],
  health_conditions: [],
};

const PreferencesContext = createContext<PreferencesContextType>({
  preferences: defaultPreferences,
  setPreferences: () => {},
  savePreferences: async () => {},
  loadPreferences: async () => {},
  migratePreferencesToUser: async () => {},
});

export const PreferencesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const [preferences, setPreferencesState] = useState<Preferences>(defaultPreferences);

  // Load preferences on initial mount and when user changes
  useEffect(() => {
    const loadPrefs = async () => {
      try {
        // If user is logged in, try to get preferences from database
        if (user) {
          const { data, error } = await supabase
            .from('preferences')
            .select('preferences')
            .eq('user_id', user.id)
            .single();
          
          if (!error && data && data.preferences) {
            console.log('Loaded user preferences from database:', data.preferences);
            setPreferencesState(data.preferences);
            return;
          }
        }
        
        // Otherwise try to get from localStorage
        const localPrefs = localStorage.getItem('preferences');
        if (localPrefs) {
          const parsedPrefs = JSON.parse(localPrefs);
          console.log('Loaded preferences from localStorage:', parsedPrefs);
          setPreferencesState(parsedPrefs);
        }
      } catch (err) {
        console.error('Error loading preferences:', err);
      }
    };
    
    loadPrefs();
  }, [user]);

  // Handle recipe saving
  useEffect(() => {
    const saveRecipe = async () => {
      if (!user) return;
      
      const recipe_id = localStorage.getItem('recipe_to_save');
      if (!recipe_id) return;
      
      try {
        await supabase
          .from('saved_recipe')
          .insert({
            user_id: user.id,
            recipe_id: recipe_id,
          });
        localStorage.removeItem('recipe_to_save');
      } catch (error) {
        console.error('Error saving recipe:', error);
      }
    };

    saveRecipe();
  }, [user]);

  // Save preferences function
  const savePreferences = async () => {
    console.log('Saving preferences:', preferences);
    
    try {
      // Save to database if user is logged in
      if (user) {
        // Check if user already has preferences
        const { data } = await supabase
          .from('preferences')
          .select('id')
          .eq('user_id', user.id)
          .single();
        
        if (data) {
          // Update existing preferences
          await supabase
            .from('preferences')
            .update({ preferences })
            .eq('user_id', user.id);
          console.log('Updated preferences in database');
        } else {
          // Insert new preferences
          await supabase
            .from('preferences')
            .insert([{ user_id: user.id, preferences }]);
          console.log('Inserted new preferences in database');
        }
      }
      
      // Always save to localStorage as backup
      localStorage.setItem('preferences', JSON.stringify(preferences));
      console.log('Saved preferences to localStorage');
    } catch (err) {
      console.error('Error saving preferences:', err);
    }
  };

  // Update preferences and save
  const setPreferences = (newPreferences: Preferences) => {
    console.log('Setting new preferences:', newPreferences);
    setPreferencesState(newPreferences);
    
    // Save to localStorage immediately
    localStorage.setItem('preferences', JSON.stringify(newPreferences));
    
    // If user is logged in, save to database
    if (user) {
      // Use setTimeout to ensure state is updated before saving
      setTimeout(async () => {
        try {
          // Check if user already has preferences
          const { data } = await supabase
            .from('preferences')
            .select('id')
            .eq('user_id', user.id)
            .single();
          
          if (data) {
            // Update existing preferences
            await supabase
              .from('preferences')
              .update({ preferences: newPreferences })
              .eq('user_id', user.id);
            console.log('Updated preferences in database');
          } else {
            // Insert new preferences
            await supabase
              .from('preferences')
              .insert([{ user_id: user.id, preferences: newPreferences }]);
            console.log('Inserted new preferences in database');
          }
        } catch (err) {
          console.error('Error saving preferences to database:', err);
        }
      }, 0);
    }
  };

  // Load preferences manually
  const loadPreferences = async () => {
    try {
      // If user is logged in, try to get preferences from database
      if (user) {
        const { data, error } = await supabase
          .from('preferences')
          .select('preferences')
          .eq('user_id', user.id)
          .single();
        
        if (!error && data && data.preferences) {
          console.log('Manually loaded user preferences from database:', data.preferences);
          setPreferencesState(data.preferences);
          return;
        }
      }
      
      // Otherwise try to get from localStorage
      const localPrefs = localStorage.getItem('preferences');
      if (localPrefs) {
        const parsedPrefs = JSON.parse(localPrefs);
        console.log('Manually loaded preferences from localStorage:', parsedPrefs);
        setPreferencesState(parsedPrefs);
      }
    } catch (err) {
      console.error('Error manually loading preferences:', err);
    }
  };

  // Migrate preferences from localStorage to user account
  const migratePreferencesToUser = async () => {
    if (!user) return;
    
    try {
      const localPrefs = localStorage.getItem('preferences');
      if (!localPrefs) return;
      
      // Check if user already has preferences
      const { data } = await supabase
        .from('preferences')
        .select('id')
        .eq('user_id', user.id)
        .single();
      
      // Don't overwrite existing preferences
      if (data) return;
      
      // Save localStorage preferences to user account
      const parsedPrefs = JSON.parse(localPrefs);
      await supabase
        .from('preferences')
        .insert([{ user_id: user.id, preferences: parsedPrefs }]);
      
      console.log('Migrated preferences from localStorage to user account');
    } catch (err) {
      console.error('Error migrating preferences:', err);
    }
  };

  return (
    <PreferencesContext.Provider value={{
      preferences,
      setPreferences,
      savePreferences,
      loadPreferences,
      migratePreferencesToUser
    }}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => useContext(PreferencesContext);
