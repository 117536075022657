import RecipeList from './RecipeList';

function LandingPage() {
  return (
    <>
      {<RecipeList />}
    </>
  );
}

export default LandingPage;