import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Drawer } from '@mui/material';
import { useAuth } from './AuthContext';
import supabase from './Supabase';
import NeedsLogin from './NeedsLogin';
import './RecipeDetails.css';
import { Recipe } from './RecipeList';
import { usePreferences } from './PreferencesContext';

const dailyValues = {
  totalFat: 65, // grams
  saturatedFat: 20, // grams
  transFat: 2, // grams
  cholesterol: 300, // milligrams
  sodium: 2300, // milligrams
  totalCarbohydrates: 300, // grams
  fiber: 28, // grams
  sugar: 100, // grams (no specific DV, considered under discretionary calorie intake)
  protein: 50, // grams
  potassium: 4700, // milligrams
};

const hasValidNutritionFacts = (nutritionFacts: any) => {
  const requiredFields = [
    'calories', 'totalFat', 'saturatedFat', 'transFat', 'cholesterol',
    'sodium', 'totalCarbohydrates', 'fiber', 'sugar', 'protein', 'potassium', 'iron'
  ];
  return requiredFields.every(field => nutritionFacts[field] !== undefined && nutritionFacts[field] !== null);
};

interface RecipeDetailsProps {
  recipe: Recipe;
  onClose: (isRecipeSaved: boolean) => void;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setSignup: React.Dispatch<React.SetStateAction<number>>;
}

const RecipeDetails: React.FC<RecipeDetailsProps> = ({ recipe, onClose, setError, setSignup }) => {
  const [recipeTabIndex, setRecipeTabIndex] = useState(0);
  const [isRecipeSaved, setIsRecipeSaved] = useState(false);
  const [needsLoginModal, setNeedsLoginModal] = useState(false);
  const { user } = useAuth();

  const { preferences } = usePreferences();
  const [servingSize, setServingSize] = useState(preferences.serving_size ? Number(preferences.serving_size.split(' ')[0]) : 1);

  const incrementServingSize = () => {
    if (servingSize < 10) {
      setServingSize(servingSize + 1);
    }
  };

  const decrementServingSize = () => {
    if (servingSize > 1) {
      setServingSize(servingSize - 1);
    }
  };

  useEffect(() => {
    if (user && recipe) {
      checkIfRecipeIsSaved();
    }
  }, [user, recipe]);

  const checkIfRecipeIsSaved = async () => {
    try {
      const { data, error } = await supabase
        .from('saved_recipe')
        .select('*')
        .eq('user_id', user.id)
        .eq('recipe_id', recipe.id)
        .single();

      if (error && error.code !== 'PGRST116') {
        console.error('Error checking if recipe is saved:', error);
      } else {
        setIsRecipeSaved(!!data);
      }
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  };

  const handleSaveOrUnsaveRecipe = async () => {
    if (!user) {
      localStorage.setItem('recipe_to_save', recipe.id.toString());
      setNeedsLoginModal(true);
      return;
    }

    try {
      if (isRecipeSaved) {
        const { error } = await supabase
          .from('saved_recipe')
          .delete()
          .eq('user_id', user.id)
          .eq('recipe_id', recipe.id);

        if (error) {
          console.error('Error unsaving recipe:', error);
          setError('Failed to unsave the recipe. Please try again.');
        } else {
          setIsRecipeSaved(false);
        }
      } else {
        const { error } = await supabase
          .from('saved_recipe')
          .insert({
            user_id: user.id,
            recipe_id: recipe.id,
          });

        if (error) {
          console.error('Error saving recipe:', error);
          setError('Failed to save the recipe. Please try again.');
        } else {
          setIsRecipeSaved(true);
        }
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setError('Failed to save the recipe. Please try again.');
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setRecipeTabIndex(newValue);
  };

  const needsLoginDrawer = (
    <Drawer
      anchor={'bottom'}
      open={needsLoginModal}
      onClose={() => setNeedsLoginModal(false)}
    >
      <NeedsLogin
        onSignupClick={() => { setNeedsLoginModal(false); setSignup(1); }}
        onLoginClick={() => { setNeedsLoginModal(false); setSignup(2); }}
        onDismissClick={() => setNeedsLoginModal(false)}
      />
    </Drawer>
  );

  function scaleQuantity(input: string, scaleFactor: number): string {
    // Handle the special case 'to taste'
    if (input.toLowerCase() === "to taste" || input.toLowerCase() === "optional") {
      return input;
    }

    // Updated regular expression to match quantities (e.g., "1/4", "3") and optionally units (e.g., "cup", "tbsp")
    const regex = /(\d+\/\d+|\d+\.?\d*)\s*(cup|cups|tbsp|tablespoon|tablespoons|tsp|teaspoon|teaspoons)?/;
    const match = input.match(regex);

    if (match) {
      // Extract the numeric quantity and optionally the unit from the match
      const quantity = match[1];
      const unit = match[2] || ''; // Default to empty string if no unit is provided

      // Calculate the new quantity by evaluating the fraction and multiplying by scaleFactor
      const newQuantity = eval(quantity) * scaleFactor;

      // Determine if the new unit should be plural
      let newUnit = unit;
      if (newUnit && newQuantity !== 1 && !unit.endsWith('s')) {
        newUnit += 's'; // Add 's' to make unit plural if necessary
      }

      // Format the new quantity; reduce it to a simpler fraction or fix to 2 decimal places if needed
      const formattedQuantity = newQuantity % 1 === 0 ? newQuantity : newQuantity.toFixed(2);

      // Check for unit to format output properly
      if (newUnit) {
        return `${formattedQuantity} ${newUnit}`;
      } else {
        return `${formattedQuantity}`;
      }
    }

    console.log(input);
    return "Invalid input"; // Return error message if input does not match expected format
  }

  return (
    <div className='recipe-container'>
      <div className='recipe-title'>
        {recipe.title}
      </div>
      <div className='recipe-description'>
        {recipe.longHealthyDescription}
      </div>

      <Tabs
        className='recipe-tabs'
        variant='fullWidth'
        TabIndicatorProps={{
          style: {
            backgroundColor: "#b84f28"
          }
        }}
        sx={{
          "& .MuiTab-root": {
            color: "#121212",
            minHeight: "80px",
            height: "80px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "500 !important",
          },
          "& .Mui-selected": {
            color: "#b84f28 !important",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "700 !important",
          },
        }}
        value={recipeTabIndex}
        onChange={handleTabChange}
        aria-label="recipe tabs"
      >
        <Tab label="INGREDIENTS" />
        <Tab label="INSTRUCTIONS" />
      </Tabs>

      {recipeTabIndex === 0 && (
        <>
          <div className='serving-size-container'>
            <div className='ingredient-header'>
              Serving size
            </div>
            <div className='serving-size-control-container'>
              <button className='serving-size-button' onClick={decrementServingSize}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 16.0001C24 16.6808 23.45 17.2308 22.7692 17.2308H9.23077C8.55 17.2308 8 16.6808 8 16.0001C8 15.3193 8.55 14.7693 9.23077 14.7693H22.7692C23.45 14.7693 24 15.3193 24 16.0001Z" fill="black" />
                </svg>
              </button>
              <div className='serving-size-display'>{servingSize}</div>
              <button className='serving-size-button' onClick={incrementServingSize}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.2308 9.23077C17.2308 8.55 16.6808 8 16 8C15.3192 8 14.7692 8.55 14.7692 9.23077V14.7692H9.23077C8.55 14.7692 8 15.3192 8 16C8 16.6808 8.55 17.2308 9.23077 17.2308H14.7692V22.7692C14.7692 23.45 15.3192 24 16 24C16.6808 24 17.2308 23.45 17.2308 22.7692V17.2308H22.7692C23.45 17.2308 24 16.6808 24 16C24 15.3192 23.45 14.7692 22.7692 14.7692H17.2308V9.23077Z" fill="#121212" />
                </svg>
              </button>
            </div>
          </div>
          <ul className='recipe-ingredient-list'>
            {recipe.fullIngredients.map((ingredient, index) => {
              return <li key={index} className='recipe-ingredient-item'>
                <div className='recipe-ingredient-details'>
                  <div className='recipe-ingredient-name'>{ingredient.name}</div>
                  <div className='recipe-ingredient-detail'>{ingredient.detail}</div>
                </div>
                <div className='recipe-ingredient-quantity'>
                  {scaleQuantity(ingredient.amount, servingSize)}
                </div>
              </li>
            })}
          </ul>
          {hasValidNutritionFacts(recipe.nutritionFacts) &&
            <div className='nutrition-facts-container'>
              <div className='nutrition-facts-title'>Nutrition Facts</div>
              <div className='nutrition-facts'>
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-heading'>Calories {recipe.nutritionFacts.calories}</div>
                  <div className='nutrition-facts-heading-values'>
                    <div className='nutrition-facts-unit'>Unit</div>
                    <div className='nutrition-facts-dv'>DV</div>
                  </div>
                </div>
                <hr />
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-label'>Total Fat</div>
                  <div className='nutrition-facts-result-values'>
                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.totalFat}g</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.totalFat / dailyValues.totalFat * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-inner-row'>
                  <div className='nutrition-facts-label'>Saturated Fat</div>
                  <div className='nutrition-facts-result-values'>
                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.saturatedFat}g</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.saturatedFat / dailyValues.saturatedFat * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-inner-row'>
                  <div className='nutrition-facts-label'>Trans Fat</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.transFat}g</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.transFat / dailyValues.transFat * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-label'>Cholesterol</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.cholesterol}mg</div>
                    <div className='nutrition-facts-percent'>&nbsp;</div>
                  </div>
                </div>
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-label'>Sodium</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.sodium}mg</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.sodium / dailyValues.sodium * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-label'>Total Carbohydrate</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.totalCarbohydrates}g</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.totalCarbohydrates / dailyValues.totalCarbohydrates * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-inner-row'>
                  <div className='nutrition-facts-label'>Fibre</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.fiber}g</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.fiber / dailyValues.fiber * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-inner-row'>
                  <div className='nutrition-facts-label'>Sugars</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.sugar}g</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.sugar / dailyValues.sugar * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-label'>Protein</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.protein}g</div>
                  </div>
                </div>
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-label'>Potassium</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.potassium}mg</div>
                    <div className='nutrition-facts-percent'>{Math.round(recipe.nutritionFacts.potassium / dailyValues.potassium * 100)}%</div>
                  </div>
                </div>
                <div className='nutrition-facts-row'>
                  <div className='nutrition-facts-label'>Iron</div>
                  <div className='nutrition-facts-result-values'>

                    <div className='nutrition-facts-subvalue'>{recipe.nutritionFacts.iron}mg</div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
      {recipeTabIndex === 1 && (
        <ul className='recipe-instruction-list'>
          {recipe.instructions.map((instruction, index) => (
            <div className='recipe-instruction-container'>
              <span key={index} className='recipe-instruction-number'>{`${index + 1}.`}</span>
              <span key={index} className='recipe-instruction-text'>{`${instruction}`}</span>
            </div>
          ))}
        </ul>
      )}
      <div className='recipe-ingredient-disclaimer-title'>Always follow food safety practices.</div>
      <div className='recipe-ingredient-disclaimer-description'>
        This recipe is crafted with the magic of AI while following the guidelines reviewed by a dietitian.
        For more information, you can view the Recommended Food Safety Guidelines.
      </div>
      <div className='recipe-close-button-container'>
        <button className='recipe-close-button' onClick={() => onClose(isRecipeSaved)}>
          Close
        </button>
      </div>
      {!isRecipeSaved &&
        <button className='recipe-save-button' onClick={handleSaveOrUnsaveRecipe}>
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.23077 1.43286C9.23077 0.752087 8.68077 0.202087 8 0.202087C7.31923 0.202087 6.76923 0.752087 6.76923 1.43286V6.97132H1.23077C0.55 6.97132 0 7.52132 0 8.20209C0 8.88286 0.55 9.43286 1.23077 9.43286H6.76923V14.9713C6.76923 15.6521 7.31923 16.2021 8 16.2021C8.68077 16.2021 9.23077 15.6521 9.23077 14.9713V9.43286H14.7692C15.45 9.43286 16 8.88286 16 8.20209C16 7.52132 15.45 6.97132 14.7692 6.97132H9.23077V1.43286Z" fill="white" />
          </svg>
          Save Recipe
        </button>
      }
      {isRecipeSaved &&
        <button className='recipe-unsave-button' onClick={handleSaveOrUnsaveRecipe}>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 15.9998C24 16.6806 23.45 17.2306 22.7692 17.2306H9.23077C8.55 17.2306 8 16.6806 8 15.9998C8 15.319 8.55 14.769 9.23077 14.769H22.7692C23.45 14.769 24 15.319 24 15.9998Z" fill="#121212" />
          </svg>
          Unsave Recipe
        </button>
      }
      {needsLoginDrawer}
    </div>
  );
};

export default RecipeDetails;
